import Logo from '../assets/svg/logo.svg';
import { MainAnimation } from './components/Animations/MainAnimation/MainAnimation';
import { Footer } from './components/Footer/Footer';
import { Navigation } from './components/Navigation/Navigation';
import './scss/index.scss'

import { render } from 'preact';

const App = () => {
  return (
    <div className="Body">
      <Navigation />

      <div className="Main">
        <div className="Main__Title">
          <Logo className="Main__Logo"></Logo>nirica
        </div>
        <div className="Main__Claim">Explore Onirica, where digital dreams and reality converge</div>
        <div className="Main__Button">
          <button className="Button MainButton">Explore Experiences <span className="Button__Icon material-symbols-rounded">chevron_right</span></button>
        </div>
      </div>
      <div className="Section Description">
        Dive into Onirica, where digital dreams and reality converge, enhanced by the power of generative AI. Here, the virtual and tangible realms blend seamlessly, driven by community-created content. Onirica invites you to shape a future where creativity knows no bounds, and every experience is personalized. Join us in pioneering a space where your imagination brings digital and real worlds to life
      </div>
      <div className="Section">
        <div className="Columns">
          <div className="Columns__Column">
            <div className="Columns__Title">
              Democratized Creation
            </div>
            Onirica utilizes low code/no code platforms, enabling users to easily create and customize digital experiences, making technology creation accessible to everyone.
          </div>
          <div className="Columns__Column">
            <div className="Columns__Title">
              Immersive Technologies
            </div>
            The platform integrates VR and AR to deliver unparalleled immersive experiences, blending the digital and physical worlds in innovative ways.
          </div>
          <div className="Columns__Column">
            <div className="Columns__Title">
              Community Collaboration
            </div>
            At its core, Onirica champions a community-driven approach, empowering users to contribute content, share ideas, and shape the platform's evolution.
          </div>
          <div className="Columns__Column">
            <div className="Columns__Title">
              AI-Driven Personalization
            </div>
            Leveraging AI, Onirica enhances user experiences with generative content creation tools, intelligent recommendations, and adaptive environments, fostering a continually engaging and evolving ecosystem.
          </div>
        </div>
      </div>
      <Footer />
      <MainAnimation />

    </div>
  );
}

render(App(), document.body);