import { useEffect, useRef, useState } from "preact/hooks";
import { isMobileResolution } from "../../../utils/resolution";
import './main-animation.scss';
import * as THREE from 'three';
import { ImprovedNoise } from 'three/examples/jsm/math/ImprovedNoise';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export const MainAnimation = () => {
  const canvasRef = useRef(null);
  const [isMobile, setIsMobile] = useState(true);
  const [context, setContext] = useState(null);
  const [canvas, setCanvas] = useState(null);

  let camera = null;
  let renderer = null;
  let scene = null;
  let cache = {};
  let cylinderGeometry = null;
  let cylinderMaterial = null;

  const onResize = () => {
    setIsMobile(isMobileResolution());
  }

  useEffect(() => {
    window.addEventListener('resize', onResize);

    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    console.log('init', canvasRef.current, canvas, context);

    canvasRef.current.width = window.innerWidth;
    canvasRef.current.height = window.innerHeight;

    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, alpha: true, antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);

    draw(renderer.context);
  }, [canvasRef.current, context]);

  const draw = () => {
    camera.position.z = 5;

    const cylinderHeight = 7;
    const cylinderRadius = 1;
    const cylinderRadialSegments = 80;
    const cylinderHeightSegments = 100;
    cylinderGeometry = new THREE.CylinderGeometry(cylinderRadius, cylinderRadius, cylinderHeight, cylinderRadialSegments, cylinderHeightSegments, true);

    //create a vertex shade with perlin noise

    cylinderMaterial = new THREE.ShaderMaterial({
      side: THREE.DoubleSide,
      transparent: true,
      wireframe: false,
      uniforms: {
        time: { value: 1.0 },
        resolution: { value: new THREE.Vector2() },
        uvRate1: { value: new THREE.Vector2(1, 1) }
      },
      vertexShader: `
        uniform float time;
        varying vec2 vUv;

        // Improved Perlin noise function
        float ImprovedNoise1(float x) {
          float x1 = x + 1.0;
          float x2 = x - 1.0;
          float n1 = fract(sin(x) * 43758.5453);
          float n2 = fract(sin(x1) * 43758.5453);
          float n3 = fract(sin(x2) * 43758.5453);
          return mix(mix(n1, n2, smoothstep(0.0, 1.0, x)), n3, smoothstep(0.0, 1.0, x));
        }
    
        // Perlin noise function
        float pnoise(float x, float y, float z) {
          vec3 p = vec3(x, y, z);
          vec3 i = floor(p);
          vec3 f = fract(p);
    
          f = f * f * (3.0 - 2.0 * f);
    
          float n = i.x + i.y * 157.0 + 113.0 * i.z;
    
          return mix(mix(mix(ImprovedNoise1(n), ImprovedNoise1(n + 1.0), f.x),
                         mix(ImprovedNoise1(n + 157.0), ImprovedNoise1(n + 158.0), f.x), f.y),
                     mix(mix(ImprovedNoise1(n + 113.0), ImprovedNoise1(n + 114.0), f.x),
                         mix(ImprovedNoise1(n + 270.0), ImprovedNoise1(n + 271.0), f.x), f.y), f.z);
        }
        
        void main() {
          vUv = uv;
          vec3 pos = position;
          float scale = 2.0;
          float amplitude = 0.5;
          
          // Utilizing Perlin noise for smoother effect
          float noise = pnoise((pos.x + time) * scale, (pos.y + time) * scale, (pos.z + time) * scale);
          pos.z += amplitude * noise;
        
          gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
        }
      `,
      fragmentShader: `
        uniform float time;
        varying vec2 vUv;
        
        void main() {
          // Create a gradient based on the v-coordinate of vUv
          float gradient = 1.0 - vUv.y; // Assuming vUv.y goes from 0.0 at the bottom to 1.0 at the top of the cylinder

          // Use the gradient to interpolate the alpha value from 1 to 0
          gl_FragColor = vec4(1.0, 51 / 255, 1.0, vUv.y * vUv.y);
        }
      `
    });


    // const wireframeMaterial = new THREE.MeshBasicMaterial({
    //   color: 0xffffff,
    //   wireframe: true
    // });

    const cylinder = new THREE.Mesh(cylinderGeometry, cylinderMaterial);
    cylinder.rotation.x = Math.PI / 2;
    cylinder.position.y = 0.8;
    cylinder.position.z = 2.8;
    scene.add(cylinder);


    camera.lookAt(0, 0, 0);

    requestAnimationFrame(animate);
  }

  const animate = (delta) => {
    if (!renderer || !scene || !camera) {
      return;
    }

    cylinderMaterial.uniforms.time.value = delta * 0.001;

    requestAnimationFrame(animate);
    renderer.render(scene, camera);
  }

  return (
    <div className="MainAnimation">
      <canvas ref={canvasRef} className="MainAnimation__Canvas"></canvas>
    </div>
  );

};