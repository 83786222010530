import Logo from '../../assets/svg/logo.svg';
import { isMobileResolution } from '../../utils/resolution';
import './navigation.scss';
import { useEffect, useRef, useState } from 'preact/hooks';
import { Fragment } from 'preact';

export const Navigation = () => {
  const [isMobile, setIsMobile] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOnTop, setIsOnTop] = useState(true);

  const navigationRef = useRef(null);

  const onResize = () => {
    setIsMobile(isMobileResolution());
  }

  const onScroll = () => {
    if (window.scrollY === 0) {
      setIsOnTop(true);
    } else {
      setIsOnTop(false);
    }
  }

  const addEventListeners = () => {
    window.addEventListener('resize', onResize);
    window.addEventListener('scroll', onScroll);
  }

  const removeEventListeners = () => {
    window.removeEventListener('resize', onResize);
    window.removeEventListener('scroll', onScroll);
  }

  useEffect(() => {
    addEventListeners();

    requestAnimationFrame(() => {
      onResize();
    });

    return removeEventListeners;
  }, [])

  const handleClickOnMenu = () => {
    console.log('click');
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <>
      {isMobile && 
        <div className={isMenuOpen ? "Navigation__Menu Navigation__Menu--open" : "Navigation__Menu"}>
          <div className="Navigation__CloseButton" onClick={handleClickOnMenu} >
            <span class="material-symbols-rounded">close</span>
          </div>
          <nav className="MobileNavigation">
          <ul >
            <li className="Navigation__Item">
              <a className="Navigation__Link" href="/">Home</a>
            </li>
            <li className="Navigation__Item">
              <a className="Navigation__Link" href="/about">About</a>
            </li>
          </ul>
          </nav>
        </div>
      }
      <nav ref={navigationRef} className={isOnTop ? "Navigation" : "Navigation Navigation--sticky" }>
        <Logo className="Navigation__Logo" />
        {isMobile && (
          <div className="Navigation__MenuButton" onClick={handleClickOnMenu} >
            <span class="material-symbols-rounded">menu</span>
          </div>
        )}
        {!isMobile && (
          <ul>
            <li className="Navigation__Item">
              <a className="Navigation__Link" href="/">Home</a>
            </li>
            <li className="Navigation__Item">
              <a className="Navigation__Link" href="/about">About</a>
            </li>
          </ul>
        )}
      </nav>
    </>
  );
};