import './footer.scss';

export const Footer = () => {
  return (
    <div className="Footer">
      <div className="Disclaimer">
        <a href="Terms and Conditions">T & C</a>
      </div>
      <div className="Date">
        Onirica {new Date().getFullYear()}
      </div>
      <div className="Author">
        <a target="_blank" href="https://xavicolomer.com">@xaviercolomer</a>
      </div>
    </div>
  )
};